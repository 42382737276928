import React from 'react'
import PropTypes from 'prop-types'

import { isInputWithError } from '/components/form/form-helpers'

const InputError = props => {
    if (!isInputWithError(props.meta)) return null
    return <span className="input-error">{(props.meta.error || props.meta.submitError).join(', ')}</span>
}

InputError.propTypes = {
    meta: PropTypes.object,
}

export default InputError
