import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import registerValidator from '../../validators/referral'

import FormView from './form-view'

class ReferralForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isSending: false,
            isSent: false
        }
    }

    submit = async values => {
        this.setState({ isSending: true })

        return this.props.handleSubmit(values).then(res => {
            this.setState({ isSending: false, isSent: !res })
            return res
        })
    }

    render() {
        return (
            <Form
                onSubmit={this.submit}
                validate={registerValidator}
                render={formProps => <FormView {...formProps} {...this.state} />}
            />
        )
    }
}

ReferralForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default ReferralForm
