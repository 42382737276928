import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'
import Referral from './Referral'
import Download from './Download'

const App = () => {
    return (
        <Router>
            <Header/>
            <Switch>
                <Route exact path="/" component={Download}/>
                <Route exact path="/get" component={Download}/>
                <Route path="/:name([^_]*)_:code" component={Referral}/>
                <Route component={Download}/>
            </Switch>
            <Footer/>
        </Router>
    )
}

export default App
