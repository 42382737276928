import React from 'react'
import { isIOS, isAndroid } from 'react-device-detect'

const Download = () => {
    if (isIOS) {
        window.location = process.env.APP_URL_IOS
    } else if (isAndroid) {
        window.location = process.env.APP_URL_ANDROID
    } else {
        window.location = 'https://www.cashbee.fr'
    }
    return (
        <div className="main">
            <div className="main-content">
                Si vous n’êtes pas redirigé, <a className="highlighted -small" href="">cliquez-ici</a>
            </div>
        </div>
    )
}

export default Download
