import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import InputError from '/components/form/input-error'

import { isInputWithError } from '/components/form/form-helpers'

const FormInput = props => {
    const classes = classNames('input-block', props.className, { '-hasError': isInputWithError(props.meta) })

    return (
        <div className={classes}>
            {props.label && <label className="input-label">{props.label}</label>}
            {props.children}
            {props.showError && <InputError meta={props.meta}/>}
        </div>
    )
}


FormInput.propTypes = {
    children: PropTypes.node.isRequired,
    label: PropTypes.string,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    showError: PropTypes.bool,
}

FormInput.defaultProps = {
    showError: true
}

export default FormInput
