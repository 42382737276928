import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'react-final-form'
import IntlTelInput from 'react-intl-tel-input'
import 'react-intl-tel-input/dist/main.css'
import classNames from 'classnames'

import FormInput from '/components/form/form-input'

import { isInputWithError } from '/components/form/form-helpers'

const PhoneInput = props =>
    <Field name={props.name}>
        {({ input, meta }) => (
            <FormInput label={props.label} meta={meta} className={classNames(props.blockClassName, '-phoneInput')}
                       showError={props.showError}>
                <IntlTelInput
                    defaultValue={input.value}
                    preferredCountries={['fr']}
                    onPhoneNumberChange={(isValid, value, country) => input.onChange(window.intlTelInputUtils.formatNumber(value, country.iso2, 0))}
                    onPhoneNumberBlur={input.onBlur}
                    format={true}
                    inputClassName={classNames('input-text', '-phone', { '-hasError': isInputWithError(meta) })}
                    disabled={props.disabled}
                />
                {props.children}
            </FormInput>
        )}
    </Field>

PhoneInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    children: PropTypes.node,
    blockClassName: PropTypes.string,
    showError: PropTypes.bool,
    disabled: PropTypes.bool,
}

PhoneInput.defaultProps = {
    showError: true
}

export default PhoneInput
