export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function parseJSON(response) {
    if (response.status === 204 || response.status === 205) {
        return null;
    }
    if (response.headers.get('Content-Type').includes('application/json')) {
        return response.json();
    }
    return response;
}

export function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    return response.json().then((json) => {
        if (json.codes && json.codes.phone && json.codes.phone[0]) {
            const error = new Error(json.codes.phone[0])
            error.response = json.codes.phone[0]
            error.status = response.status
            throw error
        } else {
            const error = new Error(response.statusText)
            error.response = response
            error.status = response.status
            throw error
        }
    });
}

export function request(url, options = {}) {
    const headers = {
        'Content-Type': 'application/json',
    };
    const newOptions = { ...options, headers: { ...headers, ...options.headers } }
    return fetch(url, newOptions)
        .then(checkStatus)
        .then(parseJSON)
}
