import React from 'react'

const Header = () => {
    return (
        <header className="header">
            <div className="header-wrap">
                <div className="header-contentWrap">
                    <a href="https://www.cashbee.fr" className="header-logo">Cashbee</a>
                </div>
            </div>
        </header>
    )
}

export default Header
