import React, { Component } from 'react'
import PropTypes from 'prop-types'

import i18n from '../../locales'

import PhoneInput from '../form/phone-input'
import Bubble from '../shared/bubble'

class FormView extends Component {

    constructor(props) {
        super(props)

        this.state = {
            isBubbleVisible: false,
        }
    }

    showBubble = () => {
        this.setState({ isBubbleVisible: true })
    }

    hideBubble = () => {
        this.setState({ isBubbleVisible: false })
    }

    render() {
        return (
            <form className="referral-form" onSubmit={this.props.handleSubmit}>
                <PhoneInput name="phoneNumber" blockClassName="-inline"
                            disabled={this.props.isSending || this.props.isSent}>
                    {this.props.isSent &&
                    <p className="input-success">{i18n.t('referral.submit-success-message')}</p>
                    }
                </PhoneInput>
                <div className="input-block -inline">
                    {this.props.isSent &&
                    <p className="button-success -cover">{i18n.t('referral.submit-success-button')}</p>
                    }
                    <button type="submit" className="button" onMouseEnter={this.showBubble}
                            onMouseLeave={this.hideBubble}>{i18n.t('referral.submit')}</button>
                </div>
            </form>
        )
    }

}

FormView.propTypes = {
    isSending: PropTypes.bool.isRequired,
    isSent: PropTypes.bool.isRequired,
}

export default FormView
