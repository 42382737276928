import validator from 'validator'
import i18n from '/locales'

const validationMethods = {
    isRequired: value => !!value && value.toString().length > 0,
    isEmail: value => !!value && validator.isEmail(value),
    isEqualTo: (value, matchValue) => value === matchValue,
    minLength: (value, min) => value.toString().length >= min,
    maxLength: (value, max) => value.toString().length <= max,
    containsNumber: value => value && /[0-9]+/.test(value),
    containsCapital: value => value && /[A-Z]+/.test(value),
    isPhoneNumber: value => intlTelInputUtils.isValidNumber(value),
    isAccepted: value => value === true,
}

class ValuesValidator {

    constructor(values, validations) {
        this.values = values
        this.validations = validations
        this.errors = {}
    }

    validate = (field, rules) => {
        const value = this.values[field]

        rules.some(rule => {
            const method = typeof rule === 'string' ? rule : rule[0]
            const options = typeof rule === 'string' ? {} : rule[1]

            if (typeof validationMethods[method] == 'function') {
                if (!this.execValidation(method, value, options)) {
                    this.addError(field, method, options)
                    return true // exits the loop
                }
            } else {
                console.error(`Unknown validator ${rule} on field ${field}`)
            }

            return false
        })
    }

    execValidation = (method, value, options) => {
        switch (method) {
            case 'isEqualTo':
                return validationMethods.isEqualTo(value, this.values[options.field])
            case 'minLength':
            case 'maxLength':
                return validationMethods[method](value, options.count)
            default:
                return validationMethods[method](value)
        }
    }

    addError = (field, rule, options = {}) => {
        const error = this.getErrorMessage(rule, options)
        this.errors[field] = this.errors[field] || []
        this.errors[field].push(error)
        return error
    }

    getErrorMessage(rule, options = {}) {
        let message = i18n.t(`formErrors.${rule}`, options) || rule

        for (let key in options) {
            message = message.replace(`%{${key}}`, options[key])
        }

        return message
    }

    getErrors() {
        for (let field in this.validations) {
            this.validate(field, this.validations[field])
        }

        return this.errors
    }

}

export default ValuesValidator
