import { FORM_ERROR } from 'final-form'

export const handleServerErrors = e => {
    if (e.response && e.response.data && e.response.data.errors) {
        const errors = {}

        Object.keys(e.response.data.errors).forEach(field =>
            errors[field] = e.response.data.errors[field].map(error => error.message)
        )

        return errors
    } else return { [FORM_ERROR]: 'An error occured' }
}

export const isInputWithError = meta => {
    return meta.touched && (meta.error || meta.submitError)
}
