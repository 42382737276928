import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// locales are downloaded from phraseapp with yarn get-locales
import fr from './fr.json'
import en from './en.json'

const languages = { fr, en }
const resources = {}

Object.keys(languages).forEach(language => {
  const translation = languages[language]
  resources[language] = { translation }
})

i18n
  .use(initReactI18next)
  .init({
    lng: window._locale,
    resources: resources,
    fallbackLng: 'fr',
    whitelist: Object.keys(resources)
  })

export default i18n
