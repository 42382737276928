import React from 'react'
import ReferralForm from './form'
import { capitalizeFirstLetter, request } from '../../utils'
import i18n from '../../locales'

const Referral = ({ match }) => {
    async function submit(values) {
        const url = `${process.env.API_URL}/share/v1/referral`
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                phone: values.phoneNumber,
                code: match.params.code,
            })
        }
        return request(url, options)
            .then(res => {
                return undefined
            }).catch(res => {
                if (res.status === 404) {
                    return { phoneNumber: [i18n.t('referral.submit-wrong-code')] }
                } else if (res.status === 422) {
                    return { phoneNumber: [i18n.t('referral.phone-taken')] }
                }
            })
    }

    return (
        <div className="main">
            <div className="main-content referral">
                <div className="referral-content">
                    <h2 className="referral-title title-1">
                        <span id="referral-name">{capitalizeFirstLetter(match.params.name)}</span>
                        <br/>
                        <span className="highlighted -small"><span>vous invite sur Cashbee</span></span>
                    </h2>
                    <p className="referral-text text">
Mettez votre argent au travail sur l'app d'épargne la plus simple qui soit.
                    </p>
                    <ReferralForm handleSubmit={submit}/>
                </div>
            </div>
        </div>
    )
}

export default Referral
