import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const Bubble = props =>
    <div className={classNames('bubble', props.className)}>
        {props.children}
    </div>

Bubble.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

export default Bubble
