import React from 'react'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="main-content text-small">
                <div className="footer-company">
                    <span className="footer-logo"/>
                    <p className="footer-address">58 rue de la Victoire, 75009 Paris — France</p>
                    <p className="footer-agreement">Etablissement agréé Banque de France</p>
                </div>
                <div className="footer-navs">
                    <div className="footer-nav">
                        <a href="https://help.cashbee.fr" className="footer-navItem">Besoin d’aide ?</a>
                        <a href="https://www.cashbee.fr/cgu/" className="footer-navItem">Conditions d'utilisation</a>
                        <a href="https://www.cashbee.fr/politique-de-confidentialite/" className="footer-navItem">
                            Politique de confidentialité
                        </a>
                        <a href="https://www.cashbee.fr/mentions-legales/" className="footer-navItem">
                            Mentions légales
                        </a>
                    </div>
                    <div className="footer-contacts">
                        <a href="https://twitter.com/Cashbee_fr"
                           className="footer-contactItem icon -twitter">Twitter</a>
                        <a href="https://www.linkedin.com/company/cashbee/"
                           className="footer-contactItem icon -linkedin">
                            LinkedIn
                        </a>
                        <a href="mailto:hello@cashbee.fr" className="footer-contactItem icon -email">Contact</a>
                        <a href="https://www.cashbee.fr/jobs/" className="footer-contactItem icon -jobs">On recrute</a>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer
