import Validator from '/services/validator'

const registerValidator = values => {
    const rules = {
        phoneNumber: ['isRequired', 'isPhoneNumber']
    }

    return new Validator(values, rules).getErrors()
}

export default registerValidator
